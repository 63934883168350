import { render, staticRenderFns } from "./Ic4gHssView.vue?vue&type=template&id=18c37d0f&scoped=true"
import script from "./Ic4gHssView.vue?vue&type=script&setup=true&lang=js"
export * from "./Ic4gHssView.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c37d0f",
  null
  
)

export default component.exports